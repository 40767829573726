import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Table, Row, Col } from 'react-bootstrap';

function ResultChecker() {
    const [result, setResult] = useState([]);
    const [student, setStudent] = useState([]);
    const { id } = useParams();



    useEffect(() => {
        axios.get(`https://martultimate.com/result/${id}`)
            .then(res => setResult(res.data))
            .catch(err => console.error('Error fetching data:', err));
    }, []);

    useEffect(() => {
        axios.get(`https://martultimate.com/student/${id}`)
            .then(res => setStudent(res.data))
            .catch(err => console.error('Error fetching data:', err));
    }, []);
    const handlePrint = () => {
        window.print();
    };

    return (
        <div>
            <Container>
                {student.map((data, index) => (
                    <Row>
                    <Col sm={12} md={3}>
                    </Col>
                    <Col sm={12} md={6}>
                    <center>
                    <h4>Plateau State Ministry of Education</h4>
                    <img className='logo' src='../logo.png' />
                    

                    <div key={index} style={{ marginBottom: '1rem', textAlign: 'left' }}>
                        <span><b>Examination Number:</b> {data.exam_number}</span><br />
                        <span><b>Name:</b> {data.name}</span><br />
                        <span><b>Gender:</b> {data.gender}</span><br />
                        <span><b>LGA:</b> {data.lga}</span><br />
                        <span><b>School:</b> {data.school}</span>
                    </div>
                    </center>
                    </Col>
                    <Col sm={12} md={3}>
                    </Col>
                    </Row>
                ))}

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Subject</th>
                            <th>Grade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {result.map((data, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data.subject}</td> {/* Adjust with actual data structure */}
                                <td>{data.grade}</td> {/* Adjust with actual data structure */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <button onClick={handlePrint} variant="primary">
                            Print Result Slip
                        </button>
            </Container>
        </div>
    );
}

export default ResultChecker;
