import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import './App.css';
import ResultChecker from './ResultChecker';
import Result from './Result';

function App() {
  return (
    <div className="app-container">
    
    <BrowserRouter>
      <div className='main-content'>
      <Routes>
        <Route path='/' element={<ResultChecker />}></Route>
        <Route path='/result/:id' element={<Result />}></Route>

      </Routes>
      </div>
    </BrowserRouter>
    
    </div>
    
  );
}

export default App;
