import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Table, Container, Form, Button, Row, Col } from 'react-bootstrap';

function ResultChecker() {
    const [examNumber, setExamNumber] = useState('');
    const [year, setYear] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [pin, setPin] = useState('');
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Make API call to fetch result data
        axios.get(`https://martultimate.com/result/${examNumber}`)
            .then(res => {
                setResult(res.data);
                setError(null);
            })
            .catch(err => {
                console.error('Error fetching data:', err);
                setError('Error fetching result. Please check your details and try again.');
                setResult(null);
            });
    };

    return (
        <div>
            <Container >
                
                <Row>
                    <Col sm={12} md={3}>
                    </Col>
                    <Col sm={12} md={6}>
                    <center>
                    <h4>Plateau State Ministry of Education</h4>
                    <img className='logo' src='./logo.png' />
                    <h4>Student Validation</h4>
                    </center>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3" controlId="formExamNumber">
                        <Form.Label column sm={3}>Exam Number</Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                type="text"
                                placeholder="Enter Examination Number"
                                value={examNumber}
                                onChange={(e) => setExamNumber(e.target.value)}
                                required
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formYear">
                        <Form.Label column sm={3}>Year</Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                type="text"
                                placeholder="Enter Year"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                required
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formSerialNumber">
                        <Form.Label column sm={3}>Serial Number</Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                type="text"
                                placeholder="Enter Serial Number"
                                value={serialNumber}
                                onChange={(e) => setSerialNumber(e.target.value)}
                                required
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPin">
                        <Form.Label column sm={3}>PIN</Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                type="password"
                                placeholder="Enter PIN"
                                value={pin}
                                onChange={(e) => setPin(e.target.value)}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <center>

                    <Link to={`/result/${examNumber}`} className="btn btn-success">
                        Check Result
                    </Link>
                    </center>
                </Form>
                </Col>
                <Col sm={12} md={3}>
                    </Col>
                </Row>
                
            </Container>
            <div className='footer'>

                </div>
        </div>
    );
}

export default ResultChecker;
